.lead-card {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  z-index: 111111111;

  height: 300px;
  width: 260px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  .close-icon-align {
    display: flex;
    justify-content: flex-end;
  }

  .lead-card-header {
    // margin-bottom: 16px;
    .detailes {
      display: flex;
      gap: 4px;
      align-items: center;
      margin-bottom: 8px;

      p {
        font-size: 15px;
        color: #555;
        margin: 0 !important;
        gap: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        strong {
          font-weight: bold;
          min-width: 100px;
        }
      }
      span {
        color: #333;
        font-size: 600;
        display: flex;
        align-items: center;
        font-size: 13px;
        margin: 0px;
      }
    }

    .lead-status {
      padding: 4px 8px;
      border-radius: 12px;
      font-size: 12px;
      text-transform: capitalize;
      color: #fff;

      &.new {
        background-color: #007bff;
      }

      &.in-progress {
        background-color: #ffc107;
      }

      &.closed {
        background-color: #28a745;
      }
    }
  }

  .lead-card-body {
    p {
      margin: 8px 0;
      font-size: 14px;
      color: #555;

      strong {
        font-weight: bold;
      }
    }
  }
}
