@charset "UTF-8";
.persönlicheDaten-white-box {
  background-color: #fff;
}
.persönlicheDaten-white-box .sub-box .sapcer {
  padding: 60px;
}
@media (max-width: 1024px) {
  .persönlicheDaten-white-box .sub-box .sapcer {
    padding: 44px;
  }
}
@media (max-width: 567px) {
  .persönlicheDaten-white-box .sub-box .sapcer {
    padding: 20px;
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .two-button-alignment {
  padding: 36px 0 0 0;
  margin: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
}
@media (max-width: 576px) {
  .persönlicheDaten-white-box .sub-box .sapcer .two-button-alignment {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    margin: 0 0 15px 0;
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .two-button-alignment button:first-child {
  border-radius: 5px;
  background: rgba(0, 96, 55, 0.4);
}
.persönlicheDaten-white-box .sub-box .sapcer .two-button-alignment button {
  min-width: 136px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  padding: 13px;
  background: #006037;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.persönlicheDaten-white-box .sub-box .sapcer .upload-mx-main {
  display: block;
}
.persönlicheDaten-white-box .sub-box .sapcer .upload-mx-main .upload-data-sc {
  padding: 36px 0 0 0;
  display: block;
}
@media (max-width: 1024px) {
  .persönlicheDaten-white-box .sub-box .sapcer .upload-mx-main .upload-data-sc {
    display: none;
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .upload-mx-main .upload-data-sc label {
  color: #848484;
  font-size: 10px;
  font-style: normal;
  display: block;
  padding: 0 0 5px 0;
  font-weight: 600;
  line-height: normal;
}
.persönlicheDaten-white-box .sub-box .sapcer .upload-mx-main .upload-data-sc .upload-box-design {
  border: 1px solid #e1e1e1;
  max-width: 140px;
  width: 100%;
  padding: 10px 13px;
  display: flex;
  cursor: pointer;
  color: #848484;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-items: center;
  position: relative;
  gap: 10px;
}
@media (max-width: 1024px) {
  .persönlicheDaten-white-box .sub-box .sapcer .upload-mx-main .upload-data-sc .upload-box-design {
    max-width: 100%;
    padding: 6px 13px;
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .upload-mx-main .upload-data-sc .upload-box-design input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.persönlicheDaten-white-box .sub-box .sapcer h1 {
  color: #4d4d4d;
  font-size: 14px;
  margin: 0 0 24px 0;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.persönlicheDaten-white-box .sub-box .sapcer .new-grid {
  grid-template-columns: 1fr 480px;
  gap: 38px;
  display: grid;
  padding: 27px 0 0 0;
}
@media (max-width: 1024px) {
  .persönlicheDaten-white-box .sub-box .sapcer .new-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .new-grid .two-col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 27px;
}
@media (max-width: 576px) {
  .persönlicheDaten-white-box .sub-box .sapcer .new-grid .two-col {
    grid-template-columns: repeat(1, 1fr);
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .main-grid {
  padding: 36px 0 0 0;
  display: grid;
  grid-template-columns: 1fr 480px;
  gap: 38px;
}
@media (max-width: 1250px) {
  .persönlicheDaten-white-box .sub-box .sapcer .main-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 1100px) {
  .persönlicheDaten-white-box .sub-box .sapcer .main-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .main-items-sc {
  display: block;
}
@media (max-width: 1024px) {
  .persönlicheDaten-white-box .sub-box .sapcer .main-items-sc {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 576px) {
  .persönlicheDaten-white-box .sub-box .sapcer .main-items-sc {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .upload-and-inp-grid-main {
  display: block;
}
@media (max-width: 1024px) {
  .persönlicheDaten-white-box .sub-box .sapcer .upload-and-inp-grid-main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 27px;
  }
}
@media (max-width: 576px) {
  .persönlicheDaten-white-box .sub-box .sapcer .upload-and-inp-grid-main {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .upload-and-inp-grid-main .upload-data {
  padding: 36px 0 0 0;
  display: none;
}
@media (max-width: 1024px) {
  .persönlicheDaten-white-box .sub-box .sapcer .upload-and-inp-grid-main .upload-data {
    padding: 0;
    display: block;
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .upload-and-inp-grid-main .upload-data label {
  color: #848484;
  font-size: 10px;
  font-style: normal;
  display: block;
  padding: 0 0 5px 0;
  font-weight: 600;
  line-height: normal;
}
.persönlicheDaten-white-box .sub-box .sapcer .upload-and-inp-grid-main .upload-data .upload-box-design {
  border: 1px solid #e1e1e1;
  max-width: 140px;
  width: 100%;
  padding: 10px 13px;
  display: flex;
  cursor: pointer;
  color: #848484;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-items: center;
  position: relative;
  gap: 10px;
}
@media (max-width: 1024px) {
  .persönlicheDaten-white-box .sub-box .sapcer .upload-and-inp-grid-main .upload-data .upload-box-design {
    max-width: 100%;
    padding: 6px 13px;
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .upload-and-inp-grid-main .upload-data .upload-box-design input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design {
  position: relative;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .image-position {
  position: absolute;
  cursor: pointer;
  top: 88px;
  right: 35px;
  z-index: 111111;
}
@media (max-width: 768px) {
  .persönlicheDaten-white-box .sub-box .sapcer .form-design .image-position {
    position: absolute;
    cursor: pointer;
    top: 25px;
    right: -342px;
    z-index: 111111;
  }
}
@media (max-width: 1024px) {
  .persönlicheDaten-white-box .sub-box .sapcer .form-design .image-position {
    position: absolute;
    cursor: pointer;
    top: 25px;
    right: -100%;
    z-index: 111111;
  }
}
@media (max-width: 820px) {
  .persönlicheDaten-white-box .sub-box .sapcer .form-design .image-position {
    position: absolute;
    cursor: pointer;
    top: 25px;
    right: -100%;
    z-index: 111111;
  }
}
@media (max-width: 576px) {
  .persönlicheDaten-white-box .sub-box .sapcer .form-design .image-position {
    position: absolute;
    cursor: pointer;
    top: 105px;
    right: 20px !important;
    z-index: 111111;
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design input {
  border: 1px solid #e1e1e1;
  padding: 0 13px;
  color: #848484;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  height: 32px;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design input:focus {
  opacity: none;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-button-main {
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  height: auto;
}
@media (max-width: 1700px) {
  .persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-button-main {
    top: -9px;
  }
}
@media (max-width: 1500px) {
  .persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-button-main {
    top: -7px;
    font-size: 11px;
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-button-main button {
  cursor: pointer;
  width: max-content;
  height: 32px;
  border: none;
  outline: none !important;
  background-color: transparent;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-button-main button:focus {
  outline: none;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: inset;
  height: 100%;
  width: 100%;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.58);
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main {
  width: fit-content;
  height: fit-content;
  gap: 10px;
  border-radius: 12px;
  background-color: #fff;
  padding: 20px 20px;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main .edit-drop-down-content {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #b5b5c3;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main .edit-drop-down-content .header-text {
  font-size: 15px;
  font-weight: 500;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main .edit-drop-down-content .fa-xmark::before {
  font-size: 25px !important;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main .edit-drop-down-body {
  width: 500px;
  height: 470px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 0;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main .edit-drop-down-body .input-search {
  padding: 0 10px 0;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main .edit-drop-down-body .input-search input[type=text] {
  outline: none;
}
@media (max-width: 1024px) {
  .persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main .edit-drop-down-body {
    width: 450px;
  }
}
@media (max-width: 576px) {
  .persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main .edit-drop-down-body {
    width: 300px;
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main .edit-drop-down-body .checckbox-alignmnent {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main .edit-drop-down-body .checckbox-alignmnent input[type=checkbox] {
  width: 16px;
  height: 16px;
  accent-color: #006037;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main .edit-drop-down-body .checckbox-alignmnent p {
  text-align: left;
  color: #000;
  font-weight: 600;
  font-size: 13px;
  margin: 0;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main .edit-drop-down-pagination {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 35px;
  border-top: 1px solid #b5b5c3;
  padding: 10px 0 0 0;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main .edit-drop-down-pagination button {
  cursor: pointer;
  background-color: #006037;
  border: none;
  color: #fff;
  border-radius: 10px;
  padding: 8px 12px;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main .edit-drop-down-pagination .butoon-alignment {
  display: flex;
  gap: 20px;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main .edit-drop-down-pagination .butoon-alignment .previus {
  cursor: pointer;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design .edit-drop-down-main .edit-drop-down-content-main .edit-drop-down-pagination .butoon-alignment .next {
  cursor: pointer;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design ::placeholder {
  font-size: 12px;
  font-style: normal;
  color: #c9c9c9;
  font-weight: 600;
  line-height: normal;
}
.persönlicheDaten-white-box .sub-box .sapcer .form-design label {
  color: #848484;
  font-size: 12px;
  font-style: normal;
  display: block;
  padding: 0 0 3px 0;
  font-weight: 600;
  line-height: normal;
}
.persönlicheDaten-white-box .sub-box .sapcer .three-col-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 36px 0 0 0;
}
.persönlicheDaten-white-box .sub-box .sapcer .three-col-grid .new-align {
  position: relative;
  left: 20px;
}
.persönlicheDaten-white-box .sub-box .sapcer .three-col-grid .new-align-left {
  padding-left: 40px;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box {
  margin: 10px 0 0 0;
  background: #f9f9f9;
  padding: 12px;
  display: grid;
  grid-template-columns: 154px 1fr;
  gap: 0;
  position: relative;
}
@media (max-width: 1024px) {
  .persönlicheDaten-white-box .sub-box .sapcer .profile-child-box {
    grid-template-columns: 124px 1fr;
    padding: 12px 12px 12px 0;
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .imagebox {
  background-color: #1f2b26;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .imagebox .liner {
  width: 4px;
  height: 100%;
  background: #41624c;
  position: absolute;
  top: 0;
  left: 0;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .imagebox .liner::before {
  position: absolute;
  width: 4px;
  height: 20px;
  background-color: #fff;
  bottom: 0;
  left: 0;
  content: "";
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .imagebox .relative-section {
  position: relative;
  background-color: #fff;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .imagebox .relative-section img {
  object-fit: cover;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .imagebox .right-sider {
  background: #41624c;
  width: 18px;
  top: 50%;
  transform: translateY(-50%);
  right: -10px;
  border-radius: 99px;
  padding: 10px 0;
  position: absolute;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .imagebox .right-sider a:last-child {
  padding: 0;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .imagebox .right-sider a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 8px;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .imagebox .right-sider a img {
  width: 10px;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .imagebox p {
  text-align: center;
  color: #fff;
  font-weight: 600;
  margin: 10px 0 2px 0;
  font-size: 12px;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .imagebox span {
  font-size: 10px;
  display: block;
  text-align: center;
  color: #fff;
  font-weight: 400;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .imagebox .profile-img-style {
  max-width: 84px;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .text-box {
  padding: 12px 18px;
  background-color: #fff;
}
@media (max-width: 1024px) {
  .persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .text-box {
    padding: 11px 10px 11px 15px;
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .text-box .text-grid {
  display: grid;
  grid-template-columns: 60px 1fr;
  padding: 0 0 5px 0;
  gap: 3px;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .text-box .text-grid p {
  color: #848484;
  margin: 0;
  word-break: break-all;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  line-height: normal;
}
@media (max-width: 1024px) {
  .persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .text-box .text-grid p {
    font-size: 9px;
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .text-box .text-grid span {
  display: block;
  color: #848484;
  margin: 0;
  word-break: break-all;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 1024px) {
  .persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .text-box .text-grid span {
    font-size: 8px;
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .text-box .child-log {
  padding: 0 0 25px 0;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-child-box .text-box .child-log img {
  width: 100px;
}
.persönlicheDaten-white-box .sub-box .sapcer .two-col-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 27px;
}
@media (max-width: 576px) {
  .persönlicheDaten-white-box .sub-box .sapcer .two-col-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.persönlicheDaten-white-box .sub-box .sapcer .two-col-grid.top-align {
  padding: 36px 0 0 0;
}
.persönlicheDaten-white-box .sub-box .sapcer .two-col-grid .child-grid {
  border: 1px solid #e1e1e1;
  height: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
}
.persönlicheDaten-white-box .sub-box .sapcer .two-col-grid .child-grid .items {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
  padding: 0 13px;
  accent-color: #1c1c1c;
}
.persönlicheDaten-white-box .sub-box .sapcer .two-col-grid .child-grid .items input[type=radio] {
  margin: 0;
  padding: 0;
  width: 13px;
  height: 13px;
}
.persönlicheDaten-white-box .sub-box .sapcer .two-col-grid .child-grid .items p {
  color: #848484;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.persönlicheDaten-white-box .sub-box .sapcer .two-col-grid .child-grid .items:nth-child(1) {
  border-right: 1px solid #e1e1e1;
}
.persönlicheDaten-white-box .sub-box .sapcer .two-col-grid .sec-col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 7px;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-details {
  padding: 0 0 12px 0;
  border-bottom: 1px solid #f7f7f7;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-details p {
  color: #4d4d4d;
  font-size: 12px;
  font-style: normal;
  margin: 0 0 12px 0;
  font-weight: 600;
  line-height: normal;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-details .profile-image-button {
  display: flex;
  align-items: center;
  gap: 9px;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-details .profile-image-button img {
  width: 54px;
  min-width: 54px;
  height: 54px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-details .profile-image-button button {
  position: relative;
  cursor: pointer;
  color: #666;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  padding: 7px 10px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
}
.persönlicheDaten-white-box .sub-box .sapcer .profile-details .profile-image-button button input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.flex-box {
  display: flex;
  align-items: center;
  gap: 10px;
  color: red;
  font-size: 10px;
}
.flex-box p {
  margin-bottom: 0 !important;
}

.flex-box-input {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.flex-box-input label {
  margin: 0 !important;
  padding: 0 !important;
}

.inputError {
  color: red;
  font-size: 10px;
  display: inline-block;
}

.required::after {
  content: " *";
  color: red;
  font-size: 10px;
}

.all-upload-data-alignment {
  padding: 20px 0 0 0;
}
.all-upload-data-alignment .box-design-style {
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  width: fit-content;
}
.all-upload-data-alignment .box-design-style .two-icon-alignment {
  display: flex;
  align-items: center;
  gap: 16px;
}
.all-upload-data-alignment .box-design-style .two-icon-alignment svg:last-child path {
  fill: red !important;
}
.all-upload-data-alignment .box-design-style svg {
  width: 12px;
}
.all-upload-data-alignment .box-design-style svg path {
  fill: #41624c;
}
.all-upload-data-alignment .box-design-style span {
  font-size: 10px;
  color: #222;
  display: block;
}

.barcode-alignment {
  padding: 5px 0 0 0;
}
.barcode-alignment img {
  width: 100%;
}

.profile-status #react-select-2-placeholder {
  color: #848484;
}
@media (max-width: 2100) {
  .profile-status > div {
    width: 100%;
    max-width: 467px;
  }
}
@media (max-width: 576px) {
  .profile-status > div {
    width: 100%;
    max-width: 360px;
    min-width: 100px;
  }
}

.modalPlzAlign {
  margin: 10px 0;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #b5b5c3;
  flex-wrap: wrap;
}
.modalPlzAlign p {
  cursor: pointer;
  background-color: #006037;
  color: white;
  padding: 8px 10px;
  border-radius: 12px;
}