.input {
  .new-map-div {
    background-color: #f3f6f9;
    border-color: #c4c4c4;
    color: #3f4254;
    margin-left: 0px;
    @media (max-width: 576px) {
      margin-left: -20px;
    }
  }
}
.icon-input-alignment {
  border: 1px solid #c4c4c4;
  height: 45px;
  background-color: #f3f6f9;
  color: #3f4254;
  border-radius: 5px;
  padding: 0px 10px;
  outline: none;
  width: 100%;
}

.new-alig-sss {
  @media (max-width: 2100px) {
    padding-left: 10px;
  }
  @media (max-width: 1500px) {
    padding-left: 10px;
  }
  @media (max-width: 576px) {
    padding-left: 20px;
  }
}
.new-alig-ssss {
  @media (max-width: 2100px) {
    padding-left: 10px;
  }
  @media (max-width: 1500px) {
    padding-left: 30px;
  }
  @media (max-width: 576px) {
    padding-left: 40px;
  }
}
