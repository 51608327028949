@charset "UTF-8";
.eingereichteLeads-page-all-content-alignment {
  background: #f9f9f9;
  padding: 30px;
  position: relative;
}
@media (max-width: 576px) {
  .eingereichteLeads-page-all-content-alignment {
    padding: 16px;
  }
}
.eingereichteLeads-page-all-content-alignment .btn-new-alig {
  display: flex;
  gap: 20px;
}
.eingereichteLeads-page-all-content-alignment .white-box-chart {
  background-color: #fff;
  padding: 30px;
  position: relative;
  height: auto;
}
@media (max-width: 1500px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart {
    height: 280px;
  }
}
@media (max-width: 1600px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart {
    height: 280px;
  }
}
@media (max-width: 1700px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart {
    height: 280px;
  }
}
@media (max-width: 576px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart {
    display: flex;
    justify-content: space-between !important;
    gap: 0;
    flex-direction: column;
    align-items: baseline !important;
  }
}
@media (max-width: 1024px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: start;
  }
}
@media (max-width: 576px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart {
    display: flex;
    justify-content: start;
    align-items: center;
  }
}
.eingereichteLeads-page-all-content-alignment .white-box-chart .new-chart-image {
  position: absolute;
  bottom: 32px;
  right: 15px;
}
@media (max-width: 576px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .new-chart-image {
    bottom: 25%;
    transform: translateY(-30%);
  }
}
@media (max-width: 1024px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart {
    width: 100%;
    height: calc(100% - 30px);
  }
}
.eingereichteLeads-page-all-content-alignment .white-box-chart .imag-alig {
  position: absolute !important;
  bottom: -5px !important;
  right: 15px !important;
  margin: 0 0 0 0;
}
@media (max-width: 2200px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .imag-alig {
    padding-bottom: 20px;
  }
}
@media (max-width: 1900px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .imag-alig {
    padding-bottom: 20px;
  }
}
@media (max-width: 1700px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .imag-alig {
    padding-bottom: 20px;
  }
}
@media (max-width: 1600px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .imag-alig {
    padding-bottom: 20px;
  }
}
@media (max-width: 1500px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .imag-alig {
    padding-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .imag-alig {
    position: static !important;
    transform: none !important;
    margin: -20px 0 10px auto;
  }
}
.eingereichteLeads-page-all-content-alignment .white-box-chart .White-chart-circle-main .white-chart-circle {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0;
}
@media (max-width: 1024px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .White-chart-circle-main .white-chart-circle {
    width: 100px !important;
    height: 100px !important;
    margin: 0;
  }
}
@media (max-width: 1500px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .White-chart-circle-main .white-chart-circle {
    width: 200px !important;
    height: 200px !important;
  }
}
@media (max-width: 576px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .White-chart-circle-main .white-chart-circle {
    margin: 0 0 0 40px;
    width: 100px !important;
    height: 100px !important;
  }
}
.eingereichteLeads-page-all-content-alignment .white-box-chart .white-chart-circle {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0;
}
@media (max-width: 1750px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .white-chart-circle {
    width: 150px !important;
    height: 150px !important;
  }
}
@media (max-width: 1400px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .white-chart-circle {
    width: 200px !important;
    height: 200px !important;
  }
}
@media (max-width: 1024px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .white-chart-circle {
    width: 100px !important;
    height: 100px !important;
    margin: 0;
  }
}
@media (max-width: 1500px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .white-chart-circle {
    width: 130px !important;
    height: 130px !important;
  }
}
@media (max-width: 576px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .white-chart-circle {
    margin: 0 !important;
  }
}
.eingereichteLeads-page-all-content-alignment .white-box-chart .white-chart-circle .cicrle-inner-text-main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 29px;
  font-weight: bold;
  color: rgb(0, 96, 55);
}
@media (max-width: 1024px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .white-chart-circle .cicrle-inner-text-main {
    font-size: 18px;
  }
}
@media (max-width: 576px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .white-chart-circle .cicrle-inner-text-main {
    font-size: 18px;
  }
}
.eingereichteLeads-page-all-content-alignment .white-box-chart .white-chart-circle .cicrle-inner-text-main .circle-div-last-text {
  font-size: 14px;
  color: rgb(0, 96, 55);
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .white-chart-circle .cicrle-inner-text-main .circle-div-last-text {
    font-size: 11px;
  }
}
@media (max-width: 576px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .white-chart-circle .cicrle-inner-text-main .circle-div-last-text {
    font-size: 9px;
  }
}
.eingereichteLeads-page-all-content-alignment .white-box-chart .white-chart-circle .white-chrt-cicrle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 29px;
  font-weight: bold;
  color: rgb(0, 96, 55);
}
@media (max-width: 1024px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .white-chart-circle .white-chrt-cicrle-text {
    font-size: 17px;
  }
}
.eingereichteLeads-page-all-content-alignment .white-box-chart .white-chart-circle .white-chrt-cicrle-text .white-chart-text-in {
  font-size: 14px;
  color: rgb(0, 96, 55);
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  .eingereichteLeads-page-all-content-alignment .white-box-chart .white-chart-circle .white-chrt-cicrle-text .white-chart-text-in {
    font-size: 9px;
  }
}
.eingereichteLeads-page-all-content-alignment .page-header-alignment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 0 18px 0;
}
@media (max-width: 700px) {
  .eingereichteLeads-page-all-content-alignment .page-header-alignment {
    padding: 0 0 19px 0;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}
.eingereichteLeads-page-all-content-alignment .page-header-alignment h2 {
  color: #383839;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.64px;
}
.eingereichteLeads-page-all-content-alignment .page-header-alignment button {
  margin: 0;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  background: #006037;
  display: flex;
  align-items: center;
  gap: 19px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.28px;
}
@media (max-width: 700px) {
  .eingereichteLeads-page-all-content-alignment .page-header-alignment button {
    margin: 0 0 0 auto;
  }
}
.eingereichteLeads-page-all-content-alignment .sec-grid {
  display: grid;
  gap: 23px;
  grid-template-columns: 410px 1fr;
}
@media (max-width: 1024px) {
  .eingereichteLeads-page-all-content-alignment .sec-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 576px) {
  .eingereichteLeads-page-all-content-alignment .sec-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.eingereichteLeads-page-all-content-alignment .sec-grid .all-content-alignment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 40px;
  border-bottom: 1px solid rgba(186, 186, 186, 0.1);
}
.eingereichteLeads-page-all-content-alignment .sec-grid .all-content-alignment h4 {
  color: #a6a6a6;
  font-size: 11.397px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
@media (max-width: 576px) {
  .eingereichteLeads-page-all-content-alignment .sec-grid .all-content-alignment {
    padding: 10px 0 10px 0;
  }
}
.eingereichteLeads-page-all-content-alignment .sec-grid .all-content-alignment:first-child h4 {
  color: #006037;
}
.eingereichteLeads-page-all-content-alignment .sec-grid .prifile-name {
  display: flex;
  align-items: center;
  gap: 30px;
}
.eingereichteLeads-page-all-content-alignment .sec-grid .prifile-name img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #006037;
}
.eingereichteLeads-page-all-content-alignment .sec-grid .prifile-name span {
  display: block;
  color: #000;
  font-size: 11.397px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.eingereichteLeads-page-all-content-alignment .sec-grid .new-class {
  display: flex;
  align-items: center;
  gap: 30px;
}
.eingereichteLeads-page-all-content-alignment .sec-grid .new-class img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  object-fit: cover;
}
.eingereichteLeads-page-all-content-alignment .sec-grid .new-class span {
  display: block;
  color: #000;
  font-size: 11.397px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.eingereichteLeads-page-all-content-alignment .sec-grid h2 {
  color: #000;
  text-align: center;
  font-size: 19.945px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
@media (max-width: 1024px) {
  .eingereichteLeads-page-all-content-alignment .sec-grid h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
  }
}
.eingereichteLeads-page-all-content-alignment .sec-grid .alignment {
  padding: 0 0 13px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.eingereichteLeads-page-all-content-alignment .sec-grid .alignment .react-datepicker-wrapper {
  width: 100%;
}
@media (max-width: 1750px) {
  .eingereichteLeads-page-all-content-alignment .sec-grid .alignment .react-datepicker-wrapper input {
    width: 100% !important;
  }
}
.eingereichteLeads-page-all-content-alignment .sec-grid .alignment h3 {
  color: #a6a6a6;
  text-align: center;
  font-size: 14.246px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media (max-width: 1024px) {
  .eingereichteLeads-page-all-content-alignment .sec-grid .alignment {
    align-items: start;
  }
  .eingereichteLeads-page-all-content-alignment .sec-grid .alignment h3 {
    margin: 0;
  }
}
.eingereichteLeads-page-all-content-alignment .new-white-box {
  background-color: #fff;
  padding: 24px;
}
.eingereichteLeads-page-all-content-alignment .first-grid {
  display: grid;
  gap: 23px;
  grid-template-columns: 410px 1fr;
  padding: 0 0 33px 0;
}
@media (max-width: 1024px) {
  .eingereichteLeads-page-all-content-alignment .first-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.eingereichteLeads-page-all-content-alignment .first-grid .first-grid-items {
  display: block;
  margin: 0;
  background-color: #fff;
  border-radius: 14.246px;
}
@media (max-width: 1024px) {
  .eingereichteLeads-page-all-content-alignment .first-grid .first-grid-items {
    display: grid;
    gap: 40px;
    margin: 0 0 30px 0;
  }
}
@media (max-width: 700px) {
  .eingereichteLeads-page-all-content-alignment .first-grid .first-grid-items {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
.eingereichteLeads-page-all-content-alignment .first-grid .first-grid-items:last-child {
  border-radius: 14.246px;
  background: #fff;
}
.eingereichteLeads-page-all-content-alignment .first-grid .first-grid-items-pp {
  display: block;
  margin: 0;
  border-radius: 14.246px;
}
@media (max-width: 1024px) {
  .eingereichteLeads-page-all-content-alignment .first-grid .first-grid-items-pp {
    display: grid;
    gap: 40px;
    margin: 0 0 30px 0;
  }
}
@media (max-width: 700px) {
  .eingereichteLeads-page-all-content-alignment .first-grid .first-grid-items-pp {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
.eingereichteLeads-page-all-content-alignment .first-grid .first-grid-items-pp .react-datepicker-wrapper {
  width: 100%;
}
.eingereichteLeads-page-all-content-alignment .first-grid .first-grid-items-pp .date-picker {
  margin-bottom: 10px;
  position: relative;
}
.eingereichteLeads-page-all-content-alignment .first-grid .first-grid-items-pp .date-picker .custom-date {
  width: 409px;
  border: 1px solid #006037;
  padding: 0 30px;
}
@media (max-width: 1024px) {
  .eingereichteLeads-page-all-content-alignment .first-grid .first-grid-items-pp .date-picker .custom-date {
    width: 100%;
    box-sizing: border-box;
  }
}
.eingereichteLeads-page-all-content-alignment .first-grid .first-grid-items-pp .date-picker .custom-date-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
}
.eingereichteLeads-page-all-content-alignment .first-grid .first-grid-items-pp:last-child {
  border-radius: 14.246px;
  background: #fff;
}
.eingereichteLeads-page-all-content-alignment .first-grid .logo-designs-xx {
  position: relative;
  margin-bottom: 30px;
}
.eingereichteLeads-page-all-content-alignment .first-grid .sub-box-pp:last-child {
  padding: 25px 40px !important;
  max-height: 410px;
}
.eingereichteLeads-page-all-content-alignment .first-grid .sub-box-pp:last-child {
  margin: 0;
}
.eingereichteLeads-page-all-content-alignment .first-grid .sub-box-pp:nth-child(2) {
  background: #ffab5e;
}
.eingereichteLeads-page-all-content-alignment .first-grid .sub-box-pp:nth-child(2) .right-content-pp h6 {
  color: #fff;
  font-size: 40px !important;
  font-weight: 600;
}
.eingereichteLeads-page-all-content-alignment .first-grid .sub-box-pp:nth-child(2) .left-content-pp {
  display: flex;
  gap: 15px;
}
.eingereichteLeads-page-all-content-alignment .first-grid .sub-box-pp:nth-child(2) .left-content-pp p {
  color: #fff;
  margin: 0px;
  font-size: 14.25px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.eingereichteLeads-page-all-content-alignment .first-grid .sub-box-margin-pp {
  margin: 10px 0px !important;
  padding: 25px 40px !important;
}
.eingereichteLeads-page-all-content-alignment .first-grid .sub-box-margin-pp .right-content-pp h6 {
  color: #006037;
  font-size: 40px !important;
  font-weight: 600;
}
.eingereichteLeads-page-all-content-alignment .first-grid .sub-box-margin-pp .left-content-pp {
  display: flex;
  gap: 15px;
}
.eingereichteLeads-page-all-content-alignment .first-grid .sub-box-margin-pp .left-content-pp p {
  color: #a2a3a3;
  margin: 0px;
  font-size: 14.25px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.eingereichteLeads-page-all-content-alignment .first-grid .sub-box-color-pp {
  background: #d12424 !important;
  margin: 0 0px 13px 0px !important;
  padding: 25px 40px !important;
}
.eingereichteLeads-page-all-content-alignment .first-grid .sub-box-color-pp .left-content-pp {
  display: flex;
  gap: 15px;
}
.eingereichteLeads-page-all-content-alignment .first-grid .sub-box-color-pp .left-content-pp p {
  color: #ffffff;
  font-size: 14.246px;
  font-style: normal;
  font-weight: 600;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.eingereichteLeads-page-all-content-alignment .first-grid .sub-box-color-pp .right-content-pp h6 {
  color: #ffffff;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.eingereichteLeads-page-all-content-alignment .first-grid .sub-box-peragraph-pp {
  color: #ffffff !important;
}
.eingereichteLeads-page-all-content-alignment .first-grid .sub-box-pp {
  border-radius: 14.246px;
  background: #fff;
  margin: 0 0 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;
}
@media (max-width: 1024px) {
  .eingereichteLeads-page-all-content-alignment .first-grid .sub-box-pp {
    margin: 0;
  }
}
.eingereichteLeads-page-all-content-alignment .our-model-design {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 35%;
  left: 40%;
  background-color: red;
}

.new-width {
  width: 450px;
}
@media (max-width: 390px) {
  .new-width {
    width: 300px !important;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected {
  background-color: green !important;
  color: white !important;
  border: 1px solid green !important;
}

.sec-grid-items {
  height: 100%;
}
@media (max-width: 2100px) {
  .sec-grid-items {
    padding-top: 30px;
  }
}
@media (max-width: 1500px) {
  .sec-grid-items {
    padding-top: 53px;
  }
}

.sec-grid-items-main {
  display: grid;
  grid-template-columns: 410px 1fr;
  align-items: center;
  gap: 20px;
}
@media (max-width: 2100px) {
  .sec-grid-items-main .sec-grid-items {
    padding-top: 34px;
  }
}
@media (max-width: 1500px) {
  .sec-grid-items-main .sec-grid-items {
    padding-top: 44px;
  }
}
@media (max-width: 576px) {
  .sec-grid-items-main .sec-grid-items {
    height: 320px;
  }
}
@media (max-width: 1750px) {
  .sec-grid-items-main {
    grid-template-columns: 338px 1fr;
  }
}
@media (max-width: 1500px) {
  .sec-grid-items-main {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 1024px) {
  .sec-grid-items-main {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 576px) {
  .sec-grid-items-main {
    grid-template-columns: repeat(1, 1fr);
  }
}

.date-picker-new {
  position: relative;
  margin-top: -12px;
  width: 100%;
}
@media (max-width: 1500px) {
  .date-picker-new {
    width: 100%;
    margin-top: 0;
  }
}
@media (max-width: 1024px) {
  .date-picker-new {
    width: auto;
  }
}
@media (max-width: 576px) {
  .date-picker-new {
    margin-top: 0;
    width: 100%;
  }
}
.date-picker-new .react-datepicker-wrapper {
  width: 100%;
}
.date-picker-new .custom-date {
  width: 409px;
  border: 1px solid #006037;
  padding: 0 30px;
}
@media (max-width: 2100px) {
  .date-picker-new .custom-date {
    width: 99%;
  }
}
@media (max-width: 1500px) {
  .date-picker-new .custom-date {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .date-picker-new .custom-date {
    width: 100%;
    box-sizing: border-box;
  }
}
@media (max-width: 576px) {
  .date-picker-new .custom-date {
    width: 340px;
    box-sizing: border-box;
  }
}
.date-picker-new .custom-date-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
}
.date-picker-new .custom-date-icon-mobile {
  display: block;
}
@media (max-width: 1024px) {
  .date-picker-new .custom-date-icon-mobile {
    display: block;
  }
}

.imag-alig {
  padding-top: 20px;
  padding-left: 20px;
}
.imag-alig .data {
  display: flex;
  gap: 10px;
  align-items: center;
}
.imag-alig .data span {
  display: block;
  background-color: #e8a56a;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-bottom: 5px;
}
.imag-alig .data p {
  color: #a2a3a3;
  margin-bottom: 5px !important;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.imag-alig .data2 {
  display: flex;
  gap: 10px;
  align-items: center;
}
.imag-alig .data2 span {
  display: block;
  background-color: #7cc5af;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-bottom: 5px;
}
.imag-alig .data2 p {
  color: #a2a3a3;
  margin-bottom: 5px !important;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.imag-alig .data3 {
  display: flex;
  gap: 10px;
  align-items: center;
}
.imag-alig .data3 span {
  display: block;
  background-color: #ea7575;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-bottom: 5px;
}
.imag-alig .data3 p {
  color: #a2a3a3;
  margin-bottom: 5px !important;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 99999;
  background-color: white; /* Optional: To maintain the background color of the header */
}

.alignment-mobile {
  flex-wrap: nowrap;
}
.alignment-mobile .mobile-view-cards-top-berater {
  display: none;
}
.alignment-mobile h2 {
  display: block;
}
@media (max-width: 1750px) {
  .alignment-mobile {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
  }
  .alignment-mobile h2 {
    display: none;
    text-align: left !important;
  }
  .alignment-mobile h3 {
    display: none;
    text-align: left !important;
  }
  .alignment-mobile .mobile-view-cards-top-berater {
    margin: 0 0 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 1750px) and (max-width: 576px) {
  .alignment-mobile .mobile-view-cards-top-berater {
    margin: 20px 0 10px 0;
  }
}
@media (max-width: 1750px) {
  .alignment-mobile .mobile-view-cards-top-berater h2 {
    text-align: left !important;
    display: block !important;
  }
  .alignment-mobile .mobile-view-cards-top-berater h3 {
    text-align: right !important;
    display: block !important;
  }
}
@media (max-width: 1750) {
  .alignment-mobile .mobile-view-cards-top-berater {
    display: none;
  }
  .alignment-mobile h2 {
    display: block;
  }
  .alignment-mobile h2 {
    display: block;
  }
}
@media (max-width: 576px) {
  .alignment-mobile {
    flex-wrap: wrap;
    gap: 10px;
  }
}

.VattelnFallLead-main {
  width: 100%;
  background: #f9f9f9;
  padding: 30px;
  position: relative;
  margin: 0 0 45px 0;
}
.VattelnFallLead-main .VattelnFallLead-container {
  max-width: 916px;
  margin: 0 auto;
  padding: 0;
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-title {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-title p {
  text-align: left;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
  color: #4d4d4d;
  margin: 0 0 12px 0;
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-title button {
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 30px;
  background-color: #006037;
  border-radius: 5px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
  cursor: pointer;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col {
  background-color: #f6eadf;
  width: calc(100% - 6px);
  padding: 5px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  gap: 10px;
  margin: 13px 0;
  position: relative;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col ::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col .status-color {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #ea7575;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col .status-color-new {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #e8a56a !important;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col .final-status-green {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #ffffff !important;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col svg {
  cursor: pointer;
  width: 17px;
  height: 9px;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col .oppen-ticket-dropdown-scroll ::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col .oppen-ticket-dropdown-scroll .oppen-ticket-dropdown-main {
  box-sizing: border-box;
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: auto;
  background-color: #f6eadf;
  border-radius: 20px;
  padding: 0 7px 7px 7px;
  max-height: 152px;
  overflow-x: hidden;
  overflow-y: auto;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col .oppen-ticket-dropdown-scroll .oppen-ticket-dropdown-main .oppen-ticket-dropdown-content {
  width: auto;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col .oppen-ticket-dropdown-scroll .oppen-ticket-dropdown-main .oppen-ticket-dropdown-content p {
  margin: 0;
  padding: 10px 0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  min-width: 180px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col .oppen-ticket-dropdown-scroll .oppen-ticket-dropdown-main .oppen-ticket-dropdown-content p:first-child {
  border-top: none !important;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col-2 {
  background-color: #006037;
  width: calc(100% - 6px);
  padding: 5px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  gap: 10px;
  margin: 13px 0;
  position: relative;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col-2 ::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col-2 .status-color {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #ea7575;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col-2 .status-color-new {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #e8a56a !important;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col-2 .final-status-green {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #ffffff !important;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col-2 svg {
  cursor: pointer;
  width: 17px;
  height: 9px;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col-2 .oppen-ticket-dropdown-main {
  box-sizing: border-box;
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: auto;
  background-color: #f6eadf;
  border-radius: 20px;
  padding: 0 7px 7px 7px;
  max-height: 152px;
  overflow-x: hidden;
  overflow-y: auto;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col-2 .oppen-ticket-dropdown-main .oppen-ticket-dropdown-content {
  width: auto;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col-2 .oppen-ticket-dropdown-main .oppen-ticket-dropdown-content p {
  margin: 0;
  padding: 10px 0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  min-width: 180px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div-status-col-2 .oppen-ticket-dropdown-main .oppen-ticket-dropdown-content p:first-child {
  border-top: none !important;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div {
  width: calc(100% - 6px);
  padding: 5px 3px;
  background-color: #f6dfdf;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  gap: 10px;
  margin: 13px 0;
  position: relative;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div .status-color {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #ea7575;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div .status-color-new {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #e8a56a !important;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div svg {
  cursor: pointer;
  width: 17px;
  height: 9px;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div .oppen-ticket-dropdown-main {
  box-sizing: border-box;
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: auto;
  background-color: #f6eadf;
  border-radius: 20px;
  padding: 0 7px 7px 7px;
  max-height: 152px;
  overflow-x: hidden;
  overflow-y: auto;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div .oppen-ticket-dropdown-main .oppen-ticket-dropdown-content {
  width: auto;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div .oppen-ticket-dropdown-main .oppen-ticket-dropdown-content p {
  margin: 0;
  padding: 10px 0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  min-width: 180px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.VattelnFallLead-main .VattelnFallLead-container .offen-ticket-div .oppen-ticket-dropdown-main .oppen-ticket-dropdown-content p:first-child {
  border-top: none !important;
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content {
  width: 100%;
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content h2 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
  color: #4d4d4d;
  margin: 0 0 25px 0;
}
@media (max-width: 576px) {
  .VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content h2 {
    margin: 0 0 13px 0;
  }
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  margin: 0 0 25px 0;
}
@media (max-width: 1024px) {
  .VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 576px) {
  .VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one {
    grid-template-columns: repeat(1, 1fr);
    margin: 0 0 13px 0;
    gap: 13px;
  }
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one .email-input-mobile {
  display: none;
}
@media (max-width: 1024px) {
  .VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one .email-input-mobile {
    display: block;
  }
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one .email-input-web {
  display: block;
}
@media (max-width: 1024px) {
  .VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one .email-input-web {
    display: none;
  }
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one .störfaktoren-input-main {
  display: block;
}
@media (max-width: 1024px) {
  .VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one .störfaktoren-input-main {
    display: none;
  }
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one .postleitzahl-inputs-main {
  display: grid;
  grid-template-columns: 153px 1fr;
  gap: 25px;
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one .postleitzahl-inputs-main .ort-input input {
  padding: 9px 13px !important;
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one label {
  font-family: "Inter", sans-serif;
  font-size: 11px !important;
  font-weight: 600;
  line-height: 12.1px;
  text-align: left;
  color: #848484;
  margin: 0 0 9px 0;
  display: block;
  width: 100%;
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one input {
  width: 100%;
  box-sizing: border-box;
  padding: 11px 13px;
  text-align: left;
  outline: none !important;
  border: 1px solid #e1e1e1;
  font-family: "Inter", sans-serif;
  font-size: 9px !important;
  font-weight: 600;
  line-height: 9.68px;
  text-align: left;
  color: #848484;
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one .eauto-inputs-main {
  display: none;
  grid-template-columns: 153px 1fr;
  gap: 25px;
}
@media (max-width: 1024px) {
  .VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one .eauto-inputs-main {
    display: grid;
  }
}
@media (max-width: 576px) {
  .VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one .eauto-inputs-main {
    grid-template-columns: 161px 1fr;
    gap: 13px;
  }
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .VattelnFallLead-main-content-one .eauto-inputs-main .ort-input input {
  padding: 9px 13px !important;
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .zusatz-div-alignment-main {
  display: grid;
  grid-template-columns: 288px 1fr;
  gap: 25px;
  margin: 0 0 25px 0;
}
@media (max-width: 1024px) {
  .VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .zusatz-div-alignment-main {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 576px) {
  .VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .zusatz-div-alignment-main {
    grid-template-columns: repeat(1, 1fr);
    gap: 13px;
    margin: 0 0 13px 0;
  }
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .zusatz-div-alignment-main h3 {
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 700;
  line-height: 13.31px;
  text-align: left;
  color: #4d4d4d;
  margin: 25px 0 0 0;
}
@media (max-width: 576px) {
  .VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .zusatz-div-alignment-main h3 {
    margin: 13px 0 0 0;
  }
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .zusatz-div-alignment-main .zusatz-inputs-main {
  display: grid;
  grid-template-columns: 153px 200px 200px;
  gap: 25px;
  width: auto;
}
@media (max-width: 1024px) {
  .VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .zusatz-div-alignment-main .zusatz-inputs-main {
    width: 288px;
  }
}
@media (max-width: 576px) {
  .VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .zusatz-div-alignment-main .zusatz-inputs-main {
    grid-template-columns: 161px 1fr;
    width: auto;
    gap: 13px;
  }
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .zusatz-div-alignment-main .zusatz-inputs-main .ort-input input {
  padding: 9px 13px !important;
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .zusatz-div-alignment-main .noticen-textarea-alignment label {
  display: block;
}
@media (max-width: 1024px) {
  .VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .zusatz-div-alignment-main .noticen-textarea-alignment label {
    display: none;
  }
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .zusatz-div-alignment-main label {
  font-family: "Inter", sans-serif;
  font-size: 11px !important;
  font-weight: 600;
  line-height: 12.1px;
  text-align: left;
  color: #848484;
  margin: 0 0 7px 0;
  display: block;
  width: 100%;
  height: 12px;
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .zusatz-div-alignment-main input {
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  padding: 11px 13px;
  text-align: left;
  outline: none !important;
  border: 1px solid #e1e1e1;
  font-family: "Inter", sans-serif;
  font-size: 9px !important;
  font-weight: 600;
  line-height: 9.68px;
  text-align: left;
  color: #848484;
}
.VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .zusatz-div-alignment-main textarea {
  width: 100%;
  height: 37px;
  box-sizing: border-box;
  padding: 11px 13px;
  text-align: left;
  outline: none !important;
  border: 1px solid #e1e1e1;
  font-family: "Inter", sans-serif;
  font-size: 9px !important;
  font-weight: 600;
  line-height: 9.68px;
  text-align: left;
  color: #848484;
  resize: none;
}
@media (max-width: 1024px) {
  .VattelnFallLead-main .VattelnFallLead-container .VattelnFallLead-main-content .zusatz-div-alignment-main textarea {
    height: 110px;
  }
}
.VattelnFallLead-main .VattelnFallLead-container .terminieren-button-main {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0;
}
@media (max-width: 1024px) {
  .VattelnFallLead-main .VattelnFallLead-container .terminieren-button-main {
    margin: 64px 0 0 0;
  }
}
@media (max-width: 576px) {
  .VattelnFallLead-main .VattelnFallLead-container .terminieren-button-main {
    margin: 13px 0 0 0;
  }
}
.VattelnFallLead-main .VattelnFallLead-container .terminieren-button-main button {
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 59px;
  background-color: #006037;
  border-radius: 5px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
  cursor: pointer;
}
.VattelnFallLead-main .VattelnFallLead-container .terminieren-button-main button:focus {
  border: none;
  outline: none;
}
@media (max-width: 576px) {
  .VattelnFallLead-main .VattelnFallLead-container .terminieren-button-main button {
    width: 100%;
  }
}
.VattelnFallLead-main .zusatzinformationen-div-main {
  margin: 0 0 30px 0;
  width: 100%;
}
.VattelnFallLead-main .zusatzinformationen-div-main h2 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
  margin: 0 0 5px 0;
  color: #4d4d4d;
}
.VattelnFallLead-main .zusatzinformationen-div-main p {
  width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  color: #4d4d4d;
  margin: 0 0 10px 0;
}
.VattelnFallLead-main .zusatzinformationen-div-main span {
  width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  color: #4d4d4d;
  margin: 0 0 10px 0;
}
.VattelnFallLead-main .zusatzinformationen-div-main textarea {
  width: 100%;
  height: 67px;
  resize: vertical;
  border: 1px solid #e1e1e1;
  padding: 20px 20px;
  background-color: transparent;
  outline: none !important;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 9.68px;
  text-align: left;
  color: #848484;
}
@media (max-width: 1024px) {
  .VattelnFallLead-main .zusatzinformationen-div-main textarea {
    font-size: 10px !important;
    font-weight: 600 !important;
    line-height: 9.68px !important;
  }
}
.VattelnFallLead-main .lead-peragraph-main {
  width: 100%;
  margin: 0 0 30px 0;
}
.VattelnFallLead-main .lead-peragraph-main p {
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 700;
  line-height: 13.31px;
  text-align: left;
  color: #4d4d4d;
}
.VattelnFallLead-main .notizen-plus-div {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 0 13px 0;
}
.VattelnFallLead-main .notizen-plus-div p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
  color: #4d4d4d;
  margin: 0;
}
.VattelnFallLead-main .notizen-plus-div svg {
  margin: 5px 5px;
}
.VattelnFallLead-main .notizen-plus-div-sc {
  display: flex;
  justify-content: start;
  align-items: end;
  margin: 0 0 13px 0;
  position: relative;
}
.VattelnFallLead-main .notizen-plus-div-sc p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
}
.VattelnFallLead-main .notizen-plus-div-sc .notizen-plus-div-sc-model-background {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(6px);
  width: 100%;
  height: 100%;
  z-index: 99;
}
.VattelnFallLead-main .notizen-plus-div-sc .notizen-plus-div-sc-model-background .notizen-plus-div-sc-model-main {
  width: 300px;
  height: 220px;
  background-color: #e3e3e3;
  border: 1px solid #4d4d4d;
  border-radius: 12px;
  overflow: hidden;
  padding: 10px;
  z-index: 9;
}
.VattelnFallLead-main .notizen-plus-div-sc .notizen-plus-div-sc-model-background .notizen-plus-div-sc-model-main .notizen-plus-div-sc-model-main-icon {
  display: flex;
  justify-content: space-between;
}
.VattelnFallLead-main .notizen-plus-div-sc .notizen-plus-div-sc-model-background .notizen-plus-div-sc-model-main .notizen-plus-div-sc-model-main-icon label {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  color: #4d4d4d;
}
.VattelnFallLead-main .notizen-plus-div-sc .notizen-plus-div-sc-model-background .notizen-plus-div-sc-model-main .notizen-plus-div-sc-model-main-icon i {
  font-size: 16px;
}
.VattelnFallLead-main .notizen-plus-div-sc .notizen-plus-div-sc-model-background .notizen-plus-div-sc-model-main textarea {
  width: 100%;
  height: 130px;
  resize: none;
  border: 1px solid #e1e1e1;
  padding: 10px 10px;
  background-color: #fff;
  outline: none !important;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  color: #848484;
  border-radius: 8px;
  outline: none;
}
@media (max-width: 576px) {
  .VattelnFallLead-main .notizen-plus-div-sc .notizen-plus-div-sc-model-background .notizen-plus-div-sc-model-main textarea {
    line-height: 20px;
  }
}
.VattelnFallLead-main .notizen-plus-div-sc .notizen-plus-div-sc-model-background .notizen-plus-div-sc-model-main .footer-button-alignment {
  display: flex;
  justify-content: end;
  margin: 12px 0 0 0;
}
.VattelnFallLead-main .notizen-plus-div-sc .notizen-plus-div-sc-model-background .notizen-plus-div-sc-model-main .footer-button-alignment button {
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  background-color: #006037;
  border-radius: 5px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
  cursor: pointer;
}
.VattelnFallLead-main .notizen-plus-div-sc .notizen-plus-div-sc-alig p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 25.94px;
  text-align: left;
  color: #4d4d4d;
  margin: 0 0 5px 0;
  display: flex;
  gap: 10px;
  align-items: center;
}
.VattelnFallLead-main .notizen-plus-div-sc .notizen-plus-div-sc-alig p svg {
  cursor: pointer;
}
.VattelnFallLead-main .notizen-plus-div-sc .notizen-plus-div-sc-alig .svg-ic-align {
  display: flex;
  gap: 10px;
}
.VattelnFallLead-main .notizen-plus-div-sc .notizen-plus-div-sc-alig .svg-ic-align span {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 11px;
  line-height: 13.31px;
  text-align: left;
}

.wattefox-data {
  color: white;
  font-size: 15px;
}

.wattefox-datas {
  color: #000;
  font-size: 15px;
}

.besten-box {
  background-color: #fff;
  padding: 14px;
  border-radius: 8px;
}
.besten-box h3 {
  font-size: 50.96px;
  font-weight: 600;
  line-height: 61.68px;
  text-align: center;
  color: #006037;
}
.besten-box .box-center-alignment-text {
  max-width: 190px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
}
.besten-box .box-center-alignment-text::before {
  height: 104%;
  background-color: #006037;
  left: 104.5px;
  top: -22px;
  content: "";
  position: absolute;
  width: 7px;
}
.besten-box .box-center-alignment-text .left-content-alignment {
  padding-left: 40px;
}
.besten-box .box-center-alignment-text .text-grid {
  display: grid;
  grid-template-columns: 14px 1fr;
  gap: 20px;
  align-items: center;
  padding: 0 0 10px 0;
}
.besten-box .box-center-alignment-text .text-grid h4 {
  font-size: 11.99px;
  font-weight: 600;
  line-height: 14.51px;
  letter-spacing: 0.02em;
  margin: 0;
  text-align: center;
  color: #006037;
}
.besten-box .box-center-alignment-text .text-grid h5 {
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
  line-height: 10px;
  text-align: left;
  margin: 0;
  color: rgba(0, 0, 0, 0.6980392157);
}
@media (max-width: 576px) {
  .besten-box .box-center-alignment-text .text-grid h5 {
    font-size: 8px;
  }
}
.besten-box .box-center-alignment-text .text-grid h5 b {
  color: #006037;
}
.besten-box .box-center-alignment-text p {
  font-size: 8.99px;
  font-weight: 600;
  line-height: 10.88px;
  letter-spacing: 0.02em;
  max-width: 52px;
  color: #006037;
  text-decoration-skip-ink: none;
}

.zusatz-div-alignment-mains {
  display: grid;
  grid-template-columns: 288px 1fr;
  gap: 25px;
  margin: 0 0 25px 0;
}
@media (max-width: 1024px) {
  .zusatz-div-alignment-mains {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 576px) {
  .zusatz-div-alignment-mains {
    grid-template-columns: repeat(1, 1fr);
    gap: 13px;
    margin: 0 0 13px 0;
  }
}
.zusatz-div-alignment-mains h3 {
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 700;
  line-height: 13.31px;
  text-align: left;
  color: #4d4d4d;
  margin: 25px 0 0 0;
}
@media (max-width: 576px) {
  .zusatz-div-alignment-mains h3 {
    margin: 13px 0 0 0;
  }
}
.zusatz-div-alignment-mains .zusatz-inputs-main {
  display: grid;
  grid-template-columns: 153px 200px 200px 200px;
  gap: 25px;
  width: auto;
}
@media (max-width: 1024px) {
  .zusatz-div-alignment-mains .zusatz-inputs-main {
    width: 288px;
  }
}
@media (max-width: 576px) {
  .zusatz-div-alignment-mains .zusatz-inputs-main {
    grid-template-columns: 161px 1fr;
    width: auto;
    gap: 13px;
  }
}
.zusatz-div-alignment-mains .zusatz-inputs-main .ort-input input {
  padding: 9px 13px !important;
}
.zusatz-div-alignment-mains .noticen-textarea-alignment label {
  display: block;
}
@media (max-width: 1024px) {
  .zusatz-div-alignment-mains .noticen-textarea-alignment label {
    display: none;
  }
}
.zusatz-div-alignment-mains label {
  font-family: "Inter", sans-serif;
  font-size: 11px !important;
  font-weight: 600;
  line-height: 12.1px;
  text-align: left;
  color: #848484;
  margin: 0 0 7px 0;
  display: block;
  width: 100%;
  height: 12px;
}
.zusatz-div-alignment-mains input,
.zusatz-div-alignment-mains select {
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  padding: 11px 13px;
  text-align: left;
  outline: none !important;
  border: 1px solid #e1e1e1;
  font-family: "Inter", sans-serif;
  font-size: 9px !important;
  font-weight: 600;
  line-height: 9.68px;
  text-align: left;
  color: #848484;
}
.zusatz-div-alignment-mains textarea {
  width: 100%;
  height: 37px;
  box-sizing: border-box;
  padding: 11px 13px;
  text-align: left;
  outline: none !important;
  border: 1px solid #e1e1e1;
  font-family: "Inter", sans-serif;
  font-size: 9px !important;
  font-weight: 600;
  line-height: 9.68px;
  text-align: left;
  color: #848484;
  resize: none;
}
@media (max-width: 1024px) {
  .zusatz-div-alignment-mains textarea {
    height: 110px;
  }
}

.new-up-btn {
  border: none;
  outline: none;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 7px !important;
  background-color: #006037;
  border-radius: 5px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  cursor: pointer;
}
@media (max-width: 576px) {
  .new-up-btn {
    display: none;
  }
}

.updated-input-design label {
  font-family: "Inter", sans-serif;
  font-size: 11px !important;
  font-weight: 600;
  line-height: 12.1px;
  text-align: left;
  color: #848484;
  margin: 0 0 7px 0;
  display: block;
  width: 100%;
  height: 12px;
}
.updated-input-design input,
.updated-input-design select {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 13px;
  text-align: left;
  outline: none !important;
  border: 1px solid #e1e1e1;
  font-family: "Inter", sans-serif;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 9.68px;
  text-align: left;
  color: #848484;
}

.new-up-btn-mob {
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 45px;
  background-color: #006037;
  border-radius: 5px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
  cursor: pointer;
}

.dropdown-modalsppop-new {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: 10px;
}
.dropdown-modalsppop-new .dropdown-modal-mds-new {
  min-width: 490px;
  max-width: 490px;
  height: 300px;
  background-color: #fff;
  height: auto;
  border-radius: 12px;
  padding: 20px 30px;
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .header-aligns {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .header-aligns .headfer-designs p {
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #706e6e;
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .header-aligns .close-icons {
  cursor: pointer;
  padding-bottom: 10px;
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .body-part-datas {
  display: flex;
  flex-direction: column;
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .body-part-datas .react-date-cus {
  position: relative;
  width: 100% !important;
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .body-part-datas .react-date-cus .dateaaaaaaa {
  margin: 0 0 13px 0;
  max-width: 409px;
  min-width: 409px;
  height: 36px;
  box-sizing: border-box;
  padding: 11px 13px;
  text-align: left;
  outline: none !important;
  border: 1px solid #e1e1e1;
  font-family: "Inter", sans-serif;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 9.68px;
  text-align: left;
  color: #848484;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
@media (max-width: 1024px) {
  .dropdown-modalsppop-new .dropdown-modal-mds-new .body-part-datas .react-date-cus .dateaaaaaaa {
    width: 100%;
    box-sizing: border-box;
  }
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .body-part-datas .custom-dropdown {
  position: relative;
  width: 100% !important;
  font-family: "Inter", sans-serif;
}
@media (max-width: 1500px) {
  .dropdown-modalsppop-new .dropdown-modal-mds-new .body-part-datas .custom-dropdown {
    width: 170px;
  }
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .body-part-datas .custom-dropdown .dropdown-header {
  margin: 0 0 13px 0;
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  padding: 11px 13px;
  text-align: left;
  outline: none !important;
  border: 1px solid #e1e1e1;
  font-family: "Inter", sans-serif;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 9.68px;
  text-align: left;
  color: #848484;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
@media (max-width: 1024px) {
  .dropdown-modalsppop-new .dropdown-modal-mds-new .body-part-datas .custom-dropdown .dropdown-header {
    margin: 0;
  }
}
@media (max-width: 576px) {
  .dropdown-modalsppop-new .dropdown-modal-mds-new .body-part-datas .custom-dropdown .dropdown-header {
    margin: 0 0 13px 0;
  }
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .body-part-datas .custom-dropdown .dropdown-header .dropdown-arrow {
  transition: transform 0.3s ease;
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .body-part-datas .custom-dropdown .dropdown-header .dropdown-arrow.open {
  transform: rotate(180deg);
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .body-part-datas .custom-dropdown .custom-dropdown-menu-main {
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  z-index: 10;
  max-height: 200px;
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
  overflow-y: auto;
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .body-part-datas .custom-dropdown .custom-dropdown-menu-main .dropdown-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid rgba(237, 237, 237, 0.9019607843);
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .body-part-datas .custom-dropdown .custom-dropdown-menu-main .dropdown-item:hover {
  background-color: #006400;
  color: #fff;
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .body-part-datas select {
  cursor: pointer;
  height: 35px;
  border: 1px solid #dfcdcd;
  padding: 0px 10px;
  width: 100%;
  border-radius: 7px;
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .body-part-datas .errorclasss {
  color: red;
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .modl-dfot-designs {
  padding: 20px 0 0 0;
  display: flex;
  justify-content: end;
}
.dropdown-modalsppop-new .dropdown-modal-mds-new .modl-dfot-designs button {
  border: none;
  background-color: #006037;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}

.zusatz-div-alignment-mains-pp {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  width: auto;
  padding-bottom: 10px;
}
@media (max-width: 1024px) {
  .zusatz-div-alignment-mains-pp {
    width: 288px;
  }
}
@media (max-width: 576px) {
  .zusatz-div-alignment-mains-pp {
    grid-template-columns: 161px 1fr;
    width: auto;
    gap: 13px;
  }
}
.zusatz-div-alignment-mains-pp .zusatz-inputs-main {
  display: grid;
  grid-template-columns: 260px 260px 260px;
  gap: 25px;
  width: auto;
}
@media (max-width: 1024px) {
  .zusatz-div-alignment-mains-pp .zusatz-inputs-main {
    width: 288px;
  }
}
@media (max-width: 576px) {
  .zusatz-div-alignment-mains-pp .zusatz-inputs-main {
    grid-template-columns: 161px 1fr;
    width: auto;
    gap: 13px;
  }
}
.zusatz-div-alignment-mains-pp .zusatz-inputs-main label {
  font-family: "Inter", sans-serif;
  font-size: 11px !important;
  font-weight: 600;
  line-height: 12.1px;
  text-align: left;
  color: #848484;
  margin: 0 0 7px 0;
  display: block;
  width: 100%;
  height: 12px;
}
.zusatz-div-alignment-mains-pp .zusatz-inputs-main input,
.zusatz-div-alignment-mains-pp .zusatz-inputs-main select {
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  padding: 11px 13px;
  text-align: left;
  outline: none !important;
  border: 1px solid #e1e1e1;
  font-family: "Inter", sans-serif;
  font-size: 9px !important;
  font-weight: 600;
  line-height: 9.68px;
  text-align: left;
  color: #848484;
}
.zusatz-div-alignment-mains-pp .zusatz-inputs-main .ort-input input {
  padding: 9px 13px !important;
}