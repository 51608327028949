.notizen-plus-div {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 0 13px 0;

  p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
    color: #4d4d4d;
    margin: 0;
  }

  svg {
    margin: 5px 5px;
  }
}
.notizen-plus-div-sc {
  display: flex;
  justify-content: start;
  align-items: end;
  margin: 0 0 13px 0;
  position: relative;

  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
  }
  .notizen-plus-div-sc-model-background {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000099;
    backdrop-filter: blur(6px);
    width: 100%;
    height: 100%;
    z-index: 99;
    .notizen-plus-div-sc-model-main {
      width: 400px;
      height: 300px;
      background-color: #e3e3e3;
      border: 1px solid #4d4d4d;
      border-radius: 12px;
      overflow: hidden;
      padding: 10px;
      z-index: 9;

      .notizen-plus-div-sc-model-main-icon {
        display: flex;
        justify-content: space-between;

        label {
          font-family: "Inter", sans-serif;
          font-size: 12px;
          font-weight: 600;
          line-height: 14.52px;
          text-align: left;
          color: #4d4d4d;
        }

        i {
          font-size: 16px;
        }
      }

      textarea {
        width: 100%;
        height: 200px;
        resize: none;
        border: 1px solid #e1e1e1;
        padding: 10px 10px;
        background-color: #fff;
        outline: none !important;
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        text-align: left;
        color: #848484;
        border-radius: 8px;
        outline: none;

        @media (max-width: 576px) {
          line-height: 20px;
        }
      }

      .footer-button-alignment {
        display: flex;
        justify-content: end;
        margin: 12px 0 0 0;

        button {
          border: none;
          outline: none;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 7px 10px;
          background-color: #006037;
          border-radius: 5px;
          color: #fff;
          font-family: "Inter", sans-serif;
          font-size: 12px;
          font-weight: 700;
          line-height: 14.52px;
          text-align: left;
          cursor: pointer;
        }
      }
    }
  }

  .notizen-plus-div-sc-alig {
    p {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 25.94px;
      text-align: left;
      color: #4d4d4d;
      margin: 0 0 5px 0;
      display: flex;
      gap: 10px;
      align-items: center;

      svg {
        cursor: pointer;
      }
    }
    .svg-ic-align {
      display: flex;
      gap: 10px;
      span {
        color: #4d4d4d;
        font-weight: 500;
        font-size: 11px;
        line-height: 13.31px;
        text-align: left;
      }
    }

    // svg {
    //   margin: 4px 4px 8px 4px;
    // }
  }
}

.dropdown-modalsppop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  .dropdown-modal-mds {
    min-width: 800px;
    max-width: 800px;
    height: 700px;
    background-color: #fff;
    height: auto;
    border-radius: 12px;
    padding: 20px 30px;

    @media (max-width: 576px) {
      min-width: 400px;
      max-width: 400px;
    }

    .header-aligns {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;

      .headfer-designs {
        p {
          font-size: 18px;
          font-weight: 500;
          border-bottom: 1px solid #706e6e;
        }
      }

      .close-icons {
        cursor: pointer;
        padding-bottom: 10px;
      }
    }

    .body-part-datas {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      p {
        font-size: 16px;
        font-weight: 500;
      }

      .file-upload {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;

        input[type="file"] {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }

      .react-date-cus {
        position: relative;
        width: 100% !important;
        .dateaaaaaaa {
          margin: 0 0 13px 0;
          max-width: 409px;
          min-width: 409px;
          height: 36px;
          box-sizing: border-box;
          padding: 11px 13px;
          text-align: left;
          outline: none !important;
          border: 1px solid #e1e1e1;
          font-family: "Inter", sans-serif;
          font-size: 12px !important;
          font-weight: 600;
          line-height: 9.68px;
          text-align: left;
          color: #848484;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          @media (max-width: 1024px) {
            width: 100%;
            box-sizing: border-box;
          }
        }
      }

      .custom-dropdown {
        position: relative;
        width: 100% !important;
        font-family: "Inter", sans-serif;

        @media (max-width: 1500px) {
          width: 170px;
        }

        .dropdown-header {
          margin: 0 0 13px 0;
          width: 100%;
          height: 36px;
          box-sizing: border-box;
          padding: 11px 13px;
          text-align: left;
          outline: none !important;
          border: 1px solid #e1e1e1;
          font-family: "Inter", sans-serif;
          font-size: 12px !important;
          font-weight: 600;
          line-height: 9.68px;
          text-align: left;
          color: #848484;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          @media (max-width: 1024px) {
            margin: 0;
          }
          @media (max-width: 576px) {
            margin: 0 0 13px 0;
          }

          .dropdown-arrow {
            transition: transform 0.3s ease;

            &.open {
              transform: rotate(180deg);
            }
          }
        }

        .custom-dropdown-menu-main {
          position: absolute;
          top: 48px;
          left: 0;
          width: 100%;
          border: 1px solid #ccc;
          border-top: none;
          border-radius: 0 0 4px 4px;
          background-color: #fff;
          z-index: 10;
          max-height: 200px;
          overflow-x: hidden;
          font-family: "Inter", sans-serif;
          overflow-y: auto;

          .dropdown-item {
            padding: 10px;
            cursor: pointer;
            border-bottom: 1px solid #ededede6;
            &:hover {
              background-color: #006400;
              color: #fff;
            }
          }
        }
      }

      select {
        cursor: pointer;
        height: 35px;
        border: 1px solid #dfcdcd;
        padding: 0px 10px;
        width: 100%;
        border-radius: 7px;
      }

      .errorclasss {
        color: red;
      }
    }

    .modl-dfot-designs {
      padding: 20px 0 0 0;
      display: flex;
      justify-content: end;

      button {
        border: none;
        background-color: #006037;
        color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
      }
    }
  }
}
.all-upload-data-alignmentss {
  padding: 0 0 0 0;

  .box-design-style {
    background-color: #f5f5f5;
    border-radius: 6px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
    width: fit-content;

    .two-icon-alignment {
      display: flex;
      align-items: center;
      gap: 16px;

      svg:last-child {
        path {
          fill: red !important;
        }
      }
    }

    svg {
      width: 12px;

      path {
        fill: #41624c;
      }
    }

    span {
      font-size: 10px;
      color: #222;
      display: block;
    }
  }
}

.notizen-plus-div-sc-alig {
  p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 25.94px;
    text-align: left;
    color: #4d4d4d;
    margin: 0 0 5px 0;
    display: flex;
    gap: 10px;
    align-items: center;

    svg {
      cursor: pointer;
    }
  }
  .svg-ic-align {
    display: flex;
    gap: 10px;
    span {
      color: #4d4d4d;
      font-weight: 500;
      font-size: 11px;
      line-height: 13.31px;
      text-align: left;
    }
  }

  // svg {
  //   margin: 4px 4px 8px 4px;
  // }
}

.upload-icon-size-change {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  svg {
    width: 16px;
    height: 16px;
  }
}

.main-relative {
  position: relative;
}

.all-upload-data-details {
  padding: 10px 0 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  .upload-data-grid {
    display: grid;
    grid-template-columns: 1fr 30px;
    padding: 3px;
    gap: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    align-items: center;
    p {
      font-size: 7px;
      color: #777;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: 500;
      margin: 0;
    }

    .two-icon-alignment {
      display: flex;
      align-items: center;
      gap: 5px;

      svg:first-child {
        position: relative;
        top: 2px;
      }

      svg {
        cursor: pointer;
        width: 12px;
        height: 12px;
        cursor: pointer;
      }
    }
  }
}
.redirect-but-dddd {
  color: white !important;

  a {
    color: white !important;
  }
}
