.akadime-page-details {
  background-color: #fff;
  margin: 0 0 60px 0;
  padding: 40px;
  @media (max-width: 576px) {
    padding: 20px;
  }

  .sub-box {
    max-width: 820px;
    margin: 0 auto;
  }

  .iframe-design {
    // background-color: #f5f5f5;
    height: 420px;
    display: flex;
    justify-content: center;
    margin-left: -17%;
    @media (max-width: 1024px) {
      height: 320px;
      margin-left: -17%;
    }
    @media (max-width: 576px) {
      height: 200px;
    }

    iframe {
      width: 100%;
      height: 100%;
    }

    body {
      justify-content: flex-start !important;
    }

    .vp-center {
      justify-content: flex-start !important;
    }
  }

  .details {
    padding: 20px 0 0 0;

    h2 {
      font-size: 20px;
      color: #000;
      font-weight: 600;
      margin: 0 0 16px 0;
    }

    p {
      font-size: 14px;
      line-height: 22px;
      color: #3d3d3d;
      margin: 0;
    }
    .pdf-alignment {
      display: flex;
      align-items: center;
      padding: 20px 0 0 0;
      gap: 10px;

      span {
        display: block;
        color: #006037;
        font-size: 14px;
        line-height: 22px;
      }
    }
    h3 {
      font-size: 14px;
      line-height: 22px;
      color: #000;
      font-weight: 600;
      margin: 16px 0 0 0;
    }

    .left-alignment {
      padding-left: 20px;
    }
  }
}
