.neueleads-container {
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  .neulead-title {
    p {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.36px;
      text-align: left;
      color: #4d4d4d;
    }
  }
  .newleads-datatable {
    padding-top: 20px;
    > div {
      > div {
        > div {
          > div {
            > div {
              border-bottom: 1px solid#4D4D4D;
            }
          }
        }
      }
    }
  }
}
.profileImage {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: #5c6a76;
}
.table-buttonalignment {
  display: flex;
  gap: 30px;
  button {
    background-color: #e5f3ec;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: center;
    color: #7cc5af;
    border: none;
    border-radius: 20px;
    padding: 5px;
  }
  // button:last-child {
  //   background-color: #f6eadf;
  //   font-family: "Inter", sans-serif;
  //   font-size: 15px;
  //   font-weight: 500;
  //   line-height: 16.94px;
  //   text-align: center;
  //   color: #e8a56a;
  //   border: none;
  //   border-radius: 20px;
  //   padding: 5px;
  // }
}
.newleads-datatable-alignment {
  overflow-x: auto;
}
.newleads-datatable-table {
  width: 100%;
  thead {
    border-bottom: 2px solid #dddddd;
    tr {
      th {
        padding: 10px;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
      }
      th:first-child {
        width: 10%;
        padding: 4px 0;
      }
      th:nth-child(3n + 2) {
        width: 45%;
      }
      th:last-child {
        width: 25%;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 2px solid #dddddd;
      font-size: 18px;
      font-weight: 500;
      padding: 5px 0;
      .category {
        font-weight: 600;
        padding: 5px 0;
      }
      td {
        padding: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        button {
          background-color: #e5f3ec;
          font-size: 15px;
          text-align: center;
          color: #7cc5af;
          border: none;
          border-radius: 20px;
          padding: 5px 20px;
          font-family: Inter, sans-serif;
          font-weight: 500;
          line-height: 16.94px;
        }
      }
    }
  }
}
.text-area-table-main {
  .text-area-table {
    width: 100%;
    position: relative;
    display: flex;
    gap: 20px;
    .text-area {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    label {
      font-size: 18px;
      font-weight: 600;
    }
    textarea {
      padding: 10px;
      width: 100%;
      // max-width: 250px;
      // min-width: 250px;
      height: 120px;
      resize: none;
    }
    .edit-button {
      margin: 33px 0 0 0;
      display: flex;
      gap: 5px;
      cursor: pointer;
      button {
        border: none;
        outline: none;
        background-color: #5c6a76;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        svg {
          path {
            fill: #fff;
          }
        }
      }
      .save-button,
      .cancel-button {
        width: auto;
        padding: 5px 10px;
        border-radius: 2px;
        font-size: 12px;
        color: #fff;
        font-weight: 500;
      }
    }
  }
}
