.akadime-page-alignment {
  background-color: #fff;
  padding: 40px;
  @media (max-width: 576px) {
    padding: 20px;
  }
  h2 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    color: #000;
    margin: 0 0 20px 0;
    @media (max-width: 576px) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .text-style {
    padding: 0 0 40px 0;
    p {
      font-size: 16px;
      line-height: 26px;
      color: #000;
      margin: 0 0 16px 0;
      @media (max-width: 576px) {
        font-size: 14px;
        line-height: 24px;
        margin: 0;
      }
    }
  }

  .three-col-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
      gap: 30px;
    }
    @media (max-width: 576px) {
      grid-template-columns: 1fr;
      gap: 20px;
    }

    .three-col-grid-items {
      h3 {
        font-size: 18px;
        margin: 0 0 16px 0;
        font-weight: 600;
        line-height: 16.04px;
        text-align: left;
      }

      .details-alignment {
        padding: 16px 0 0 0;

        h4 {
          font-size: 16px;
          font-weight: 600;
          color: #000;
        }

        p {
          font-size: 14px;
          line-height: 24px;
          color: #000;
          font-weight: 400;
          margin: 0 0 16px 0;
          min-height: 72px;
        }

        button {
          cursor: pointer;
          a {
            text-decoration: none;
            color: #fff;
          }
          border: none;
          padding: 10px 32px;
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          box-shadow: 0px 4px 4px 0px #00000029;
          background: #9f9fa0;
          border-radius: 999px;
          &:hover {
            background: #41624c;
          }
        }
      }

      .image-box {
        width: 100%;
        height: 240px;
        background-color: #f5f5f5;
        position: relative;

        .icon-alignment {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
