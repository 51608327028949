/* DatePicker.css */

.custom-datepicker .react-datepicker {
  background-color: #f0f0f0; /* Background color of the date picker */
  border: 1px solid #2684ff; /* Border color */
  border-radius: 8px; /* Border radius */
}

.custom-datepicker .react-datepicker__header {
  background-color: #007bff; /* Header background color */
  color: white; /* Header text color */
}

.custom-datepicker .react-datepicker__day--selected,
.custom-datepicker .react-datepicker__day--in-selecting-range,
.custom-datepicker .react-datepicker__day--in-range {
  background-color: #007bff; /* Selected day background color */
  color: white; /* Selected day text color */
}

.custom-datepicker .react-datepicker__day--keyboard-selected {
  background-color: #0056b3; /* Keyboard selected day background color */
  color: white; /* Keyboard selected day text color */
}

/* Add more styles as needed */
.custom-date {
  min-height: 38px;
  min-width: 142px;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 5px;
  padding: 0px 8px;
  color: #5c6a76;
  align-items: center;
  display: flex;
}

.project-Dropdown {
  min-width: 135px;
  max-height: 34px;
  color: #5c6a76;
}

.project-Dropdown-pp {
  min-width: 162px;
  max-height: 34px;
  color: #5c6a76;
}

.filter-alignment {
  display: flex;
  gap: 20px;
  margin: 0 61px 0 0 !important;

  @media (max-width: 800px) {
    margin: 0 0 0 20px !important;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    margin: 0 0 0 20px !important;
  }
}

.header-alignment {
  display: flex;
  justify-content: space-between;
}

.new-deisgnpppp {
  background-color: #f6eadf;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #e8a56a;
}

.new-deisgn-pppp {
  background-color: #e5f3ec;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #006037;
}

.new-deisgn-ppppp {
  background-color: #f6dfdf;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #d12424;
}

.date-picker {
  display: flex;
  gap: 10px;

  .icon-alig-xo {
    display: flex;
    align-items: center;
    margin-top: -2px;
  }
}
.react-datepicker__day--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected {
  background-color: #41624C !important;
  color: white !important;
  border: 1px solid  #41624C  !important;
}

.react-datepicker__day--selected:hover {
  background-color:  #41624C  !important;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-range:hover {
  background-color:  #41624C  !important;
  color: white !important;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-start:hover,
.react-datepicker__day--range-end,
.react-datepicker__day--range-end:hover {
  background-color:  #41624C  !important;
  color: white !important;
}

/* General hover styles for all days */
.react-datepicker__day:hover {
  background-color:  #41624C  !important;
  color: white !important;
}

/* Hover state during range selection */
.react-datepicker__day--in-selecting-range:hover {
  background-color:  #41624C  !important;
  color: white !important;
}

/* Hover state for days within the range selection process */
.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-start:hover,
.react-datepicker__day--selecting-range-end,
.react-datepicker__day--selecting-range-end:hover {
  background-color:  #41624C  !important;
  color: white !important;
}

/* Hover state during range selection */
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-selecting-range:hover {
  background-color:  #41624C  !important;
  color: white !important;
}
.oppen-ticket-dropdown-content{
  cursor: pointer;
}