.calendar-app-layout {
  background: #f9f9f9;
  padding: 30px 100px;
  position: relative;
  margin: 0;

  @media (max-width: 1480px) {
    padding: 30px 30px;
  }
  @media (max-width: 1024px) {
    margin: 60px 0 0 0;
  }
  @media (max-width: 576px) {
    padding: 10px 10px;
    margin: 0;
  }

  .termine-title {
    display: flex;
    justify-content: start;
    align-items: start;
    position: relative;
    flex-direction: column;

    h1 {
      color: #4d4d4d;
      font-size: 16px;
      font-weight: 700;
      line-height: 16.94px;
      text-align: left;
      font-family: "Inter", sans-serif;
    }

    span {
      height: 1px;
      width: 77px;
      display: block;
      // background-color: #4d4d4d;
    }
  }
  .header-title-design {
    display: flex;
    gap: 470px;
    @media (max-width:1900px) {
      gap: 470px;

    }

    @media (max-width:1700px) {
      gap: 150px;
    }
    h1 {
      color: #4d4d4d;
      font-size: 16px;
      font-weight: 700;
      line-height: 16.94px;
      text-align: left;
      font-family: "Inter", sans-serif;
      border-bottom: 1px solid #000;
      width: fit-content;
      padding: 0 0 5px 0;
      @media (max-width:576px) {
       display: none;
      }
    }
    p{
      color: #4d4d4d;
     
      font-size: 16px;
      font-weight: 700;
      line-height: 16.94px;
      text-align: left;
      font-family: "Inter", sans-serif;
      border-bottom: 1px solid #000;
      width: fit-content;
      padding: 0 0 5px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      cursor: pointer;
    }
  }

  .calendar-app {
    text-align: center;
    margin-top: 22px;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 60px;
    @media (max-width: 1450px) {
      gap: 30px;
    }

    @media (max-width: 1024px) {
      display: block;
    }

    .controls {
      margin-bottom: 20px;

      button {
        margin: 10px;
        padding: 10px 20px;
        font-size: 1em;
        border: none;
        border-radius: 5px;
        background-color: #006400;
        color: white;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;

        &:hover {
          background-color: #004c00;
        }
      }
    }

    .calendar {
      box-sizing: border-box;
      width: 100%;
      margin: 0;
      text-align: center;
      background-color: #f9f9f9;
      border-radius: 8px;
      padding: 30px 22px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      .month-controls {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 37px;
        background-color: #f5f5f5;
        border-radius: 10px;
        margin: 0 0 17px 0;

        button {
          background-color: transparent;
          border: none !important;
          outline: none !important;
          padding: 0;
          width: 28px;
          height: 29px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .month-label {
          font-family: "Inter", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 17.05px;
          letter-spacing: -0.08em;
          text-align: center;
          color: #1f1f21;
        }
      }

      .month {
        font-size: 1.8em;
        margin-bottom: 20px;
        color: #333;
      }

      .weekdays {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        font-weight: bold;
        margin-bottom: 10px;
        gap: 10px;
        color: #666;

        div {
          padding: 0;
          font-family: "Inter", sans-serif;
          font-size: 10px;
          font-weight: 500;
          line-height: 10.06px;
          text-align: right;
          color: #1f1f21;
        }
      }

      .days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 10px;

        div {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          padding: 10px 10px;
          margin: 0;
          border-radius: 10px;
          background-color: #f5f5f5;
          transition: background-color 0.2s ease-in-out;
          height: 100%;
          width: 100%;

          @media (max-width: 576px) {
            margin: 0;
            padding: 0;
            background-color: transparent;
            box-shadow: none;
          }

          &:hover {
            background-color: #e3e3e3;
          }

          .calender-date-alignment {
            display: flex;
            justify-content: end;
            align-items: start;
            padding: 0;
            height: auto;
            width: 100%;
            background-color: transparent;
            box-shadow: none;
            margin: 0 0 10px 0;

            @media (max-width: 576px) {
              margin: 0;
              justify-content: center;
            }

            .clender-date-circle {
              margin: 0;
              padding: 0;
              height: 20px;
              width: 20px;
              border-radius: 50%;
              border: none;
              background: transparent;
              display: flex;
              justify-content: center;
              align-items: center;
              box-sizing: inherit;
              box-shadow: none;
              p {
                color: #333;
                text-align: right;
                font-family: "Inter", sans-serif;
                font-size: 10px;
                font-weight: 500;
                line-height: 10.06px;
                margin: 0;
                width: fit-content;
              }
              @media (max-width: 576px) {
                background-color: #f5f5f5;
              }
            }

            .clender-date-circle-selected {
              background: #ffffff33;
              margin: 0;
              padding: 0;
              height: 20px;
              width: 20px;
              border-radius: 50%;
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              box-sizing: inherit;
              box-shadow: none;
              p {
                color: #fff;
                text-align: right;
                font-family: "Inter", sans-serif;
                font-size: 10px;
                font-weight: 500;
                line-height: 10.06px;
                margin: 0;
                width: fit-content;
              }
            }
          }
          .Date-data {
            padding: 0;
            background-color: transparent;
            border: none;
            box-shadow: none;
            // width: 100%;
            // height: auto;
            height: 74px;
            width: 74px;
            p {
              max-width: 60px;
              word-break: break-all;
              font-size: 10px;
            }

            @media (max-width: 1400px) {
              width: 100%;
              height: 100%;
            }
            @media (max-width: 576px) {
              display: none;
            }
          }
        }

        .highlight {
          background-color: #006400;

          @media (max-width: 576px) {
            background-color: transparent;
          }

          .calender-date-alignment {
            .clender-date-circle {
              background-color: #ffffff33;

              p {
                color: #fff;
              }

              @media (max-width: 576px) {
                background-color: #006400;
              }
            }
          }
          .Date-data {
            display: block;

            @media (max-width: 576px) {
              display: none;
            }
          }

          &:hover {
            background-color: #006400;
          }
        }

        .empty {
          visibility: hidden;
        }
      }
    }

    .Calender-time-div-main {
      width: 235px;
      display: block;
      margin: 0;

      @media (max-width: 1024px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 13px;
        margin: 12px 0;
        width: 100%;
      }

      @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
        gap: 0;
      }
      .custom-dropdown {
        position: relative;
        width: 100% !important;
        font-family: "Inter", sans-serif;

        @media (max-width: 1500px) {
          width: 170px;
        }

        .dropdown-header {
          margin: 0 0 13px 0;
          width: 100%;
          height: 36px;
          box-sizing: border-box;
          padding: 11px 13px;
          text-align: left;
          outline: none !important;
          border: 1px solid #e1e1e1;
          font-family: "Inter", sans-serif;
          font-size: 12px !important;
          font-weight: 600;
          line-height: 9.68px;
          text-align: left;
          color: #848484;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          @media (max-width: 1024px) {
            margin: 0;
          }
          @media (max-width: 576px) {
            margin: 0 0 13px 0;
          }

          .dropdown-arrow {
            transition: transform 0.3s ease;

            &.open {
              transform: rotate(180deg);
            }
          }
        }

        .custom-dropdown-menu-main {
          position: absolute;
          top: 48px;
          left: 0;
          width: 100%;
          border: 1px solid #ccc;
          border-top: none;
          border-radius: 0 0 4px 4px;
          background-color: #fff;
          z-index: 10;
          max-height: 200px;
          overflow-x: hidden;
          font-family: "Inter", sans-serif;
          overflow-y: auto;

          .dropdown-item {
            padding: 10px;
            cursor: pointer;
            border-bottom: 1px solid #ededede6;
            &:hover {
              background-color: #006400;
              color: #fff;
            }
          }
        }
      }

      button {
        border: none;
        border-radius: 5px;
        width: 100%;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #006037;
        color: #fff;
        font-family: "Inter", sans-serif;
        font-size: 11.37px;
        font-weight: 700;
        line-height: 13.75px;
        text-align: left;
      }
    }
  }
 
}
.projekte-datatable-tablet {
  padding-top: 20px;
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
  @media (max-width: 576px) {
    display: block;
  }

  > div {
    > div {
      > div {
        > div {
          > div {
            border-bottom: 1px solid#4D4D4D;
            font-size: 13px;
            display: flex;
            justify-content: center;
            > div {
              color: #5c6a76;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
.current-month {
  cursor: pointer;
  width: 100%;

  @media (max-width: 576px) {
    width: fit-content !important;
  }
}
.past-date {
  background-color: #f0f0f0; // or any color to indicate it's disabled
  color: #ccc;
  cursor: not-allowed;
}

.past-date .clender-date-circle {
  opacity: 0.5; // make it look disabled
}
.selected-date {
  background-color: #006037 !important;
  color: white ;
}

.display-no {
  display: none;
}
.display-yes {
  display: block;
}
.projekte-datatable-ppppp {
  padding: 20px 0;

  @media (max-width: 1100px) {
    display: none !important;
  }
  @media (max-width: 576px) {
    display: none !important;
  }
  .dats-tatasaddd {
    min-height: 500px;
  }

  > div {
    > div {
      > div {
        > div {
          > div {
            border-bottom: 1px solid#4D4D4D;
            font-size: 15px;
            display: flex;
            justify-content: center;
            > div {
              color: #5c6a76;
            }
          }
        }
      }
    }
  }
}

.new-deisgn {
  background-color: #F6DFDF;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #EA7575;
}

.new-deisgn-status{
  background-color: #f6eadf;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color:#E8A56A}

  .dropdown-item.disabled {
    color: #ccc;
    cursor: not-allowed;
  }
  .new-AGdjdstatus{
    background-color: #006037;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: center;
    padding: 8px 10px;
    border-radius: 10px;
  }

  .current-month{
    position: relative;

    i{
      position: absolute;
      bottom: 15px;
      left: 15px;
    }
  }

  
.dropdown-modals {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0,0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: 10px;
  .dropdown-modal-mds {
      min-width: 400px;
      max-width: 520px;
height: 300px;
      background-color: #fff;
      height: auto;
      border-radius: 12px;
      padding: 20px 30px;

      .header-aligns{
        display: flex;
        justify-content: space-between;
        .headfer-designs{
          p{
            font-size: 18px;
            font-weight: 500;
            border-bottom: 1px solid #706e6e;
          }
        }

        .close-icons{
           cursor: pointer;
        }
      }


      

      .body-part-datas{
        width: 100%;
        display: flex;
        flex-direction: column;

        .custom-dropdown {
          position: relative;
          width: 100% !important;
          font-family: "Inter", sans-serif;
  
          @media (max-width: 1500px) {
            width: 170px;
          }
  
          .dropdown-header {
            margin: 0 0 13px 0;
            width: 100%;
            height: 36px;
            box-sizing: border-box;
            padding: 11px 13px;
            text-align: left;
            outline: none !important;
            border: 1px solid #e1e1e1;
            font-family: "Inter", sans-serif;
            font-size: 12px !important;
            font-weight: 600;
            line-height: 9.68px;
            text-align: left;
            color: #848484;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
  
            @media (max-width: 1024px) {
              margin: 0;
            }
            @media (max-width: 576px) {
              margin: 0 0 13px 0;
            }
  
            .dropdown-arrow {
              transition: transform 0.3s ease;
  
              &.open {
                transform: rotate(180deg);
              }
            }
          }
  
          .custom-dropdown-menu-main {
            position: absolute;
            top: 48px;
            left: 0;
            width: 100%;
            border: 1px solid #ccc;
            border-top: none;
            border-radius: 0 0 4px 4px;
            background-color: #fff;
            z-index: 10;
            max-height: 200px;
            overflow-x: hidden;
            font-family: "Inter", sans-serif;
            overflow-y: auto;
  
            .dropdown-item {
              padding: 10px;
              cursor: pointer;
              border-bottom: 1px solid #ededede6;
              &:hover {
                background-color: #006400;
                color: #fff;
              }
            }
          }
        }

        select{
          cursor: pointer;
          height: 35px;
          border: 1px solid #dfcdcd;
          padding: 0px 10px;
          width: 100%;
          border-radius: 7px;
        }

        .errorclasss{
          color: red
        }
      }

      .modl-dfot-designs{
        padding: 20px 0 0 0;
        display: flex;
        justify-content: end;

        button{
          border: none;
          background-color: #006037;
          color: #fff;
          padding: 5px 10px;
          border-radius: 5px;
        }
      }
  }
}

.selected-item-ss{
  cursor: pointer;
  background-color: #006037;
  color: white;
  padding: 8px 10px;
  border-radius: 12px;
  width: fit-content;
}